import Accordion from "../Layout/Component/Accordion";
import { useState } from "react";
const dataSet = [
    {
        office : "PT. Ezeelink Indonesia",
        title : "Backend Developer",
        year : "September 2023 - Current",
        description: [
            "•	Develop and maintain web app with ASP.NET, C# with SQL Server database",
            "•	Collaborate with frontend developer teams to successfully develop 6 projects",
            "•	Develop and deploy 8 third-party service integration for partner, and internal needs",
            "•	Built internal applications to generate & automate QRIS merchant onboarding, driving 300+ new merchant sign-ups and connected with SFTP Server",
            "•	Use WinSCP to Interact with 3rd party API",
            "•	Generate API for QRIS",
            "•	Design Database and the relation",
            "•	Use Scrum as Software Development Lifecycle"
        ],
    },
    {
        office : "PT. Adicipta Inovasi Teknologi",
        title : "Fullstack Developer",
        year : "Feb 2022 - Feb 2023",
        description: [
            "•	Implements software feature for Clients needs",
            "•	Used VB.Net & C# as main programming language and SQL Server as Database",
            "•	Experienced in Waterfall Software Development Life Cycle",
            "•	Made stored procedure for several function required in development",
            "•	Tested software feature post development phase",
            "•	Created API with REST Architecture using C#",
            "•	Maintained new feature after deployment to production",
            "•	Actively contributed to a group project where we analyzed and solved user problems, problem-solving and analytical skills.",
        ],
    }
]

const WorkingExperiences = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleAccordionChange = (idx) => {
        setExpandedIndex(idx === expandedIndex ? null : idx);
    };

    return (
        <div>
            <h1 className="kanit-medium text-5xl text-center pb-8 text-slate-300">
                Working Experiences
            </h1>
            <div className="flex justify-center">
                <div className="w-[85%]">
                {dataSet.map((data, idx) => (
                    <Accordion
                        key={idx} // Don't forget to provide a unique key for each accordion
                        header={data.office}
                        idx={idx}
                        year={data.year}
                        title={data.title}
                        description={data.description}
                        expandedIndex={expandedIndex}
                        onAccordionChange={handleAccordionChange}
                    />
                ))}
                </div>
            </div>
        </div>
    );
}

export default WorkingExperiences;